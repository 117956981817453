import { auth, firebase } from "boot/firebase";

const state = {
  drawerState: false,
  currentYear: new Date().getFullYear(),
  appTitle: "Tutto",
  appSlogan: "CRM",
  displayName: "",
  emailUser: "",
  idToken: "",
  uid: "",
  customClaims: [],
  macroProjectName: [],
  projectName: [],
};

const getters = {
  currentYear(state) {
    return state.currentYear;
  },
  drawerState(state) {
    return state.drawerState;
  },
  appTitle(state) {
    return state.appTitle;
  },
  appSlogan(state) {
    return state.appSlogan;
  },
  displayName(state) {
    return state.displayName;
  },
  emailUser(state) {
    return state.emailUser;
  },
  idToken(state) {
    return state.idToken;
  },
  uid(state) {
    return state.uid;
  },
  customClaims(state) {
    return state.customClaims;
  },
  projectName(state) {
    return state.projectName;
  },
  macroProjectName(state) {
    return state.macroProjectName;
  },

};

const mutations = {
  updateDrawerState(state, payload) {
    state.drawerState = payload;
  },
  updateDisplayName(state, payload) {
    state.displayName = payload;
  },
  updateEmailUser(state, payload) {
    state.emailUser = payload;
  },
  updateIdToken(state, payload) {
    state.idToken = payload;
  },
  updateUid(state, payload) {
    state.uid = payload;
  },
  updateCustomClaims(state, payload) {
    state.customClaims = payload;
  },
  updateProjectName(state, payload) {
    state.updateProjectName = payload;
  },
  updateMacroProjectName(state, payload) {
    state.macroProjectName = payload;
  },
};

const actions = {
  updateDrawerState({ commit }, opened) {
    commit("updateDrawerState", opened);
  },

  updateDisplayName({ commit }, displayName) {
    commit("updateDisplayName", displayName);
  },

  updateEmailUser({ commit }, email) {
    commit("updateEmailUser", email);
  },

  updateIdToken({ commit }, idToken) {
    commit("updateIdToken", idToken);
  },

  updateUid({ commit }, uid) {
    commit("updateUid", uid);
  },

  handleAuthStateChange({ commit }) {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const displayName = userAuth.displayName;
        const email = userAuth.email;
        const uid = userAuth.uid;
        commit("updateDisplayName", displayName);
        commit("updateEmailUser", email);
        commit("updateUid", uid);
        userAuth.getIdToken(true);
        await userAuth.getIdToken().then(function (data) {
          commit("updateIdToken", data);
        });
      }
    });

    auth.currentUser.getIdTokenResult().then((tokenResult) => {
      const claims = tokenResult.claims;
      commit("updateCustomClaims", claims);
    });
  },

  updateProjectName({ commit }) {
    commit("updateProjectName");
  },

  updateMacroProjectName({ commit }) {
    commit("updateMacroProjectName");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
