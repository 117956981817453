const routes = [
  {
    path: "/",
    redirect: "/app/user/home",
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    props: true,
    component: () => import("layouts/Auth.vue"),
    children: [
      {
        path: "/auth/login",
        name: "Login",
        component: () => import("pages/auth/Login.vue"),
        props: true,
      },
      // {
      //   path: "/auth/register",
      //   name: "Register",
      //   component: () => import("pages/auth/Register.vue"),
      //   props: true,
      // },
      {
        path: "/auth/recover-account",
        name: "RecoverAccount",
        component: () => import("pages/auth/RecoverAccount.vue"),
        props: true,
      },
      {
        path: "/auth/account-actions",
        name: "AccountActions",
        component: () => import("pages/auth/AccountActions.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/app",
    redirect: "/app/user/home",
    props: true,
    component: () => import("layouts/App.vue"),
    meta: {
      requireAuth: true,
      // authorizedRoles: ["user"],
    },
    children: [
      {
        path: "/app/user/home",
        name: "Home",
        component: () => import("pages/app/user/Home.vue"),
        props: true,
      },
      {
        path: "/app/user/profile",
        name: "Profile",
        component: () => import("pages/app/user/Profile.vue"),
        props: true,
      },
      {
        path: "/app/admin/dashboard",
        name: "AdminDashboard",
        component: () => import("pages/app/admin/Dashboard.vue"),
        props: true,
        meta: {
          authorizedRoles: ["ADMIN"],
        },
      },
      {
        path: "/app/agent/dashboard",
        name: "AgentDashboard",
        component: () => import("pages/app/agent/Dashboard.vue"),
        props: true,
        meta: {
          authorizedRoles: ["AGENT"],
        },
      },
      {
        path: "/app/agent/certificates",
        name: "AgentCertificates",
        component: () => import("pages/app/agent/reports/Certificates.vue"),
        props: true,
        meta: {
          authorizedRoles: ["CONTACT"],
        },
      },
      {
        path: "/app/agent/pre-delivery/preDeliveryDashboard",
        name: "preDelivery",
        component: () => import("pages/app/agent/pre-delivery/preDeliveryDashboard.vue"),
        props: true,
        meta: {
          authorizedRoles: ["PRE_DELIVERY_MANAGER", "PRE_DELIVERY"],
        },
      },
      {
        path: "/app/agent/projects-customization",
        name: "ProjectsCustomization",
        component: () => import("pages/app/agent/projects-customization/Main.vue"),
        props: true,
        meta: {
          authorizedRoles: ["CONTACT"],
        },
      },
      {
        path: "/app/agent/association-dwelling-unit",
        name: "AssociationDwellingUnit",
        component: () => import("pages/app/agent/reports/AssociationDwellingUnit.vue"),
        props: true,
        meta: {
          authorizedRoles: ["CONTACT"],
        },
      },
      {
        path: "/app/agent/pqrs/pqrsDashboard",
        name: "AgentPqrsDashboard",
        component: () => import("src/pages/app/agent/pqrs/pqrsDashboard.vue"),
        props: true,
        meta: {
          authorizedRoles: [
            "CONTACT",
            "CARTERA",
            "PROYECTOS",
            "TRAMITES_ESCRITURA",
            "DISENO",
            "COMERCIAL",
            "CONSTRUCCION",
            "ADMINISTRATIVO_ADMINISTRACIONES_PROVISIONALES",
            "NUEVOS_NEGOCIOS",
            "JURIDICO"
          ],
        },
      },
      {
        path: "/app/agent/pqrs/pqrsDashboard/pqrsAdd",
        name: "AgentPqrsAdd",
        component: () => import("src/pages/app/agent/pqrs/pqrsAdd.vue"),
        props: true,
        meta: {
          authorizedRoles: ["CONTACT"],
        },
      },
      {
        path: "/app/agent/pqrs/pqrsDashboard/details/:id",
        name: "AgentDetailsPqrs",
        component: () => import("src/pages/app/agent/pqrs/DetailsPqrs.vue"),
        props: true,
        meta: {
          authorizedRoles: [
            "CONTACT",
            "CARTERA",
            "PROYECTOS",
            "TRAMITES_ESCRITURA",
            "DISENO",
            "COMERCIAL",
            "CONSTRUCCION",
            "ADMINISTRATIVO_ADMINISTRACIONES_PROVISIONALES",
            "POSTSALE",
            "NUEVOS_NEGOCIOS",
            "JURIDICO"
          ],
        },
      },
      {
        path: "/app/agent/suppliers/suppliersDashboard",
        name: "AgentSuppliersDashboard",
        component: () => import("src/pages/app/agent/suppliers/suppliersDashboard.vue"),
        props: true,
        meta: {
          authorizedRoles: [
            "CONTACT",
            "CARTERA",
            "PROYECTOS",
            "TRAMITES_ESCRITURA",
            "DISENO",
            "COMERCIAL",
            "CONSTRUCCION",
            "ADMINISTRATIVO_ADMINISTRACIONES_PROVISIONALES",
            "NUEVOS_NEGOCIOS"
          ],
        },
      },
      {
        path: "/app/agent/post-sales/postSaleDashboard",
        name: "AgentPostSaleDashboard",
        component: () =>
          import("src/pages/app/agent/post-sales/PostSaleDashboard.vue"),
        props: true,
        meta: {
          authorizedRoles: ["CONTACT", "POSTSALE"],
        },
      },
      {
        path: "/app/agent/post-sales/postSaleDashboard/details/:id",
        name: "PostSaleDetails",
        component: () =>
          import("src/pages/app/agent/post-sales/PostSaleDetails.vue"),
        props: true,
        meta: {
          authorizedRoles: ["CONTACT", "POSTSALE"],
        },
      },
      {
        path: "/app/agent/technical-visit/technicalVisitDashboard",
        name: "AgentTechnicalVisitDashboard",
        component: () =>
          import("src/pages/app/agent/post-sales/TechnicalVisitDashboard.vue"),
        props: true,
        meta: {
          authorizedRoles: ["CONTACT", "POSTSALE"],
        },
      },
      {
        path: "/app/agent/technical-visit/technicalVisitDashboard/details/:id",
        name: "TechnicalVisitDetails",
        component: () =>
          import("src/pages/app/agent/post-sales/TechnicalVisitDetails.vue"),
        props: true,
        meta: {
          authorizedRoles: ["CONTACT", "POSTSALE"],
        },
      },
      {
        path: "/app/agent/work-order/workOrderDashboard",
        name: "WorkOrderDashboard",
        component: () =>
          import("src/pages/app/agent/post-sales/WorkOrderDashboard.vue"),
        props: true,
        meta: {
          authorizedRoles: ["CONTACT", "POSTSALE"],
        },
      },
      {
        path: "/app/agent/work-order/workOrderDashboard/details/:id",
        name: "WorkOrderDetails",
        component: () =>
          import("src/pages/app/agent/post-sales/WorkOrderDetails.vue"),
        props: true,
        meta: {
          authorizedRoles: ["CONTACT", "POSTSALE"],
        },
      },
      {
        path: "/app/agent/post-sales/pqrs/postSalePqrsDashboard",
        name: "PostSalePqrsDashboard",
        component: () =>
          import("src/pages/app/agent/post-sales/PostSalePqrsDashboard.vue"),
        props: true,
        meta: {
          authorizedRoles: ["CONTACT", "POSTSALE"],
        },
      },
    ],
  },

  {
    path: "/forbidden",
    component: () => import("src/pages/common/Forbidden.vue"),
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("src/pages/common/Error404.vue"),
  },
];

export default routes;
