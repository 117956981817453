const state = {
  modalDetalleProyecto: false,
  modalResponsablesProyecto: false,
  modalDetalleUnidad: false,
  modalAgregarObservaciones: false,
  modalEditarObservaciones: false,
};

const getters = {
  estadoModalDetalleProyecto(state) {
    return state.modalDetalleProyecto
  },
  estadoModalResponsablesProyecto(state) {
    return state.modalResponsablesProyecto
  },
  estadoModalDetalleUnidad(state) {
    return state.modalDetalleUnidad
  },
  estadoModalAgregarObservacion(state) {
    return state.modalAgregarObservaciones
  },
  estadoModalEditarObservacion(state) {
    return state.modalEditarObservaciones
  },
};

const mutations = {
  abrirModalDetalleProyecto(state) {
    state.modalDetalleProyecto = true;
  },
  cerrarModalDetalleProyecto(state) {
    state.modalDetalleProyecto = false;
  },
  abrirModalResponsablesProyecto(state) {
    state.modalResponsablesProyecto = true;
  },
  cerrarModalResponsablesProyecto(state) {
    state.modalResponsablesProyecto = false;
  },
  cambiarEstadoModalResponsablesProyecto(state) {
    state.modalResponsablesProyecto = true;
  },
  cambiarEstadoModalDetalleUnidad(state) {
    state.modalDetalleUnidad = true;
  },
  cambiarEstadoModalAgregarObservacion(state) {
    state.modalAgregarObservaciones = true;
  },
  cambiarEstadoModalEditarObservacion(state) {
    state.modalEditarObservaciones = true;
  },
};

const actions = {
  abrirModalDetalleProyecto({ commit }) {
    commit("abrirModalDetalleProyecto");
    window.history.pushState({ modal: 'DetalleProyecto' }, "", "")
  },
  cerrarModalDetalleProyecto({ commit }) {
    commit("cerrarModalDetalleProyecto");
  },
  abrirModalResponsablesProyecto({ commit }) {
    commit("abrirModalResponsablesProyecto");
    window.history.pushState({ modal: 'ResponsablesProyecto' }, "", "")
  },
  cerrarModalResponsablesProyecto({ commit }) {
    commit("cerrarModalResponsablesProyecto");
  },
  cambiarEstadoModalResponsablesProyecto({ commit }) {
    commit("cambiarEstadoModalResponsablesProyecto");
  },
  cambiarEstadoModalDetalleUnidad({ commit }) {
    commit("cambiarEstadoModalDetalleUnidad");
  },
  cambiarEstadoModalAgregarObservacion({ commit }) {
    commit("cambiarEstadoModalAgregarObservacion");
  },
  cambiarEstadoModalEditarObservacion({ commit }) {
    commit("cambiarEstadoModalEditarObservacion");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
