import { boot } from "quasar/wrappers";

import firebase from "firebase/app";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyA9PXbFYP3Tn_jcHKRzbEIK1-msf53s5IA",
  authDomain: "ms-access-control.firebaseapp.com",
  projectId: "ms-access-control",
  storageBucket: "ms-access-control.appspot.com",
  messagingSenderId: "141021280457",
  appId: "1:141021280457:web:480170202c3c8d9fcbb518",
  measurementId: "G-8WP2DR71DG",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//init services
const auth = firebase.auth();

firebase.getCurrentUser = () => {
  try {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  } catch (error) {
    console.log("EL ERROR 3", error)
  }
};

//  export vars
export { auth, firebase };
// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(async (/* { app, router, ... } */) => {
  // something to do
});
