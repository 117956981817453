<template>
  <div :style="{ color: isOffline ? 'red' : 'inherit' }">
    <router-view />
  </div>
</template>
<script>
import {
  defineComponent,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  ref,
} from "vue";
import { useQuasar } from "quasar";

export default defineComponent({
  name: "App",
  setup() {
    const isOffline = ref(!navigator.onLine);
    const quaS = useQuasar();
    function handleOnlineStatusChange() {
      isOffline.value = !navigator.onLine;
      console.warn("The App is Offline?", isOffline.value);
    }

    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

    // Intervalo para evitar navegación hacia atrás
    let resetHistoryInterval;

    const handlePopstate = (e) => {
      e.preventDefault();

      // Muestra la alerta o modal
      alert(
        "Navegación hacia atrás bloqueada. Por favor, usa los botones de la página."
      );

      // Asegura que el usuario permanezca en la misma página
      history.pushState(null, null, window.location.href);
    };

    // function handleBeforeUnload(e) {
    //   e.preventDefault();
    //   console.log("Intento de recarga el sitio");
    //   e.returnValue = "";
    // }

    onMounted(() => {
      window.addEventListener("popstate", handlePopstate); // Detecta clic en el botón "atrás"

      // if (isMobileDevice) {
      //   window.addEventListener("beforeunload", handleBeforeUnload); // Alerta en recarga del sitio en móviles
      // }

      // Configura el estado inicial para bloquear retroceso
      history.pushState(null, null, window.location.href);

      // Intervalo continuo para restablecer el historial
      resetHistoryInterval = setInterval(() => {
        history.pushState(null, null, window.location.href);
      }, 100); // Ejecuta cada 100ms para asegurar que no se retroceda

      window.addEventListener("online", handleOnlineStatusChange);
      window.addEventListener("offline", handleOnlineStatusChange);

      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener("message", handleSWMessage);
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener("popstate", handlePopstate);
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
      clearInterval(resetHistoryInterval); // Limpia el intervalo cuando se desmonta el componente
    });

    onUnmounted(() => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener("message", handleSWMessage);
      }
    });

    const handleSWMessage = (event) => {
      if (event.data && event.data.type === "PRECACHE_COMPLETE") {
        quaS.localStorage.set("precacheComplete", true);
        console.log("precacheComplete", true);
      }
    };

    return {
      isOffline,
    };
  },
});
</script>
<style>
body.offline {
  overflow: hidden;
}
</style>
